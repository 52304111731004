import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "modal-dialog modal-dialog-centered mw-800px" }
const _hoisted_2 = { class: "modal-content roundeda" }
const _hoisted_3 = { class: "modal-body" }
const _hoisted_4 = { class: "row justify-content-around" }
const _hoisted_5 = { class: "row justify-content-around" }
const _hoisted_6 = { class: "col-md-12" }
const _hoisted_7 = { class: "fv-plugins-message-container" }
const _hoisted_8 = { class: "fv-help-block" }
const _hoisted_9 = { class: "col-md-5" }
const _hoisted_10 = { class: "fv-plugins-message-container" }
const _hoisted_11 = { class: "fv-help-block" }
const _hoisted_12 = { class: "col-md-5" }
const _hoisted_13 = { class: "fv-plugins-message-container" }
const _hoisted_14 = { class: "fv-help-block" }
const _hoisted_15 = { class: "row justify-content-around" }
const _hoisted_16 = { class: "col-md-5" }
const _hoisted_17 = { class: "fv-plugins-message-container" }
const _hoisted_18 = { class: "fv-help-block" }
const _hoisted_19 = { class: "col-md-5" }
const _hoisted_20 = { class: "fv-plugins-message-container" }
const _hoisted_21 = { class: "fv-help-block" }
const _hoisted_22 = { class: "row justify-content-around" }
const _hoisted_23 = { class: "col-md-5" }
const _hoisted_24 = ["value"]
const _hoisted_25 = { class: "fv-plugins-message-container" }
const _hoisted_26 = { class: "fv-help-block" }
const _hoisted_27 = { class: "col-md-5" }
const _hoisted_28 = { class: "password-field-wrapper" }
const _hoisted_29 = { class: "fv-plugins-message-container" }
const _hoisted_30 = { class: "fv-help-block" }
const _hoisted_31 = { class: "row select-customer" }
const _hoisted_32 = {
  key: 0,
  class: "col-md-5 customer-list"
}
const _hoisted_33 = ["value"]
const _hoisted_34 = { class: "fv-plugins-message-container" }
const _hoisted_35 = { class: "fv-help-block" }
const _hoisted_36 = { class: "modal-footer flex-center" }
const _hoisted_37 = {
  type: "reset",
  id: "kt_modal_bin_cancel",
  class: "btn btn-white me-3",
  ref: "cancelRef"
}
const _hoisted_38 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" Modal "),
    _createElementVNode("div", {
      class: "modal fade",
      id: "addusermodal",
      ref: "createAPIKeyModalRef",
      tabindex: "-1",
      "aria-labelledby": "exampleModalLabel",
      "aria-hidden": "true",
      "data-allow-clear": "true",
      "data-bs-focus": "false",
      onShown: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.onModalShown && _ctx.onModalShown(...args)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[23] || (_cache[23] = _createElementVNode("div", { class: "modal-header bg-primary" }, [
            _createElementVNode("span", {
              class: "modal-title h5 text-uppercase text-white",
              id: "exampleModalLabel",
              style: {"margin-left":"1.1rem"}
            }, " Add User "),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-icon btn-sm",
              "data-bs-dismiss": "modal",
              "aria-label": "Close",
              id: "addnewnotificationmodal"
            }, [
              _createElementVNode("i", {
                class: "bi bi-x-circle-fill light-text fs-3",
                style: {"color":"white"}
              })
            ])
          ], -1 /* HOISTED */)),
          _createVNode(_component_Form, {
            class: "form w-100",
            id: "kt_user_add_form",
            onSubmit: _ctx.submit,
            "validation-schema": 
            _ctx.userRecord.is_admin == 1 ? _ctx.addAdminSchema : _ctx.adduserSchema
          
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createCommentVNode("begin::Label"),
                      _cache[12] || (_cache[12] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Select User Type", -1 /* HOISTED */)),
                      _createCommentVNode("end::Label"),
                      _createCommentVNode("begin::Select"),
                      _createVNode(_component_Field, {
                        name: "is_admin",
                        "data-control": "select2",
                        "data-hide-search": "true",
                        "data-placeholder": "User Type",
                        onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onUserTypeChange($event.target.value))),
                        class: "form-select border border-primary form-select-solid form-control-lg form-control-solid",
                        as: "select",
                        modelValue: _ctx.userRecord.is_admin,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.userRecord.is_admin) = $event))
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createElementVNode("option", { value: "0" }, "Customer's User", -1 /* HOISTED */),
                          _createElementVNode("option", { value: "1" }, "Admin", -1 /* HOISTED */)
                        ])),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue"]),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_ErrorMessage, { name: "role_id" })
                        ])
                      ]),
                      _createCommentVNode("end::Select")
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createCommentVNode("begin::Label"),
                    _cache[13] || (_cache[13] = _createElementVNode("label", { class: "required form-label fs-6 fw-bolder text-dark" }, "User Name", -1 /* HOISTED */)),
                    _createCommentVNode("end::Label"),
                    _createCommentVNode("begin::Input"),
                    _createVNode(_component_Field, {
                      class: "form-control border border-primary form-control-lg form-control-solid",
                      type: "text",
                      name: "name",
                      autocomplete: "off",
                      modelValue: _ctx.userRecord.name,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.userRecord.name) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    _createCommentVNode("end::Input"),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_ErrorMessage, { name: "name" })
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createCommentVNode("begin::Label"),
                    _cache[14] || (_cache[14] = _createElementVNode("label", { class: "required form-label fs-6 fw-bolder text-dark" }, "Email", -1 /* HOISTED */)),
                    _createCommentVNode("end::Label"),
                    _createCommentVNode("begin::Input"),
                    _createVNode(_component_Field, {
                      class: "form-control border border-primary form-control-lg form-control-solid",
                      type: "email",
                      autocomplete: "off",
                      name: "email",
                      modelValue: _ctx.userRecord.email,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.userRecord.email) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    _createCommentVNode("end::Input"),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_ErrorMessage, { name: "email" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createCommentVNode("begin::Label"),
                    _cache[15] || (_cache[15] = _createElementVNode("label", { class: "required form-label fs-6 fw-bolder text-dark" }, "Address", -1 /* HOISTED */)),
                    _createCommentVNode("end::Label"),
                    _createCommentVNode("begin::Input"),
                    _createVNode(_component_Field, {
                      class: "form-control border border-primary form-control-lg form-control-solid",
                      type: "text",
                      name: "address",
                      modelValue: _ctx.userRecord.address,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.userRecord.address) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    _createCommentVNode("end::Input"),
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", _hoisted_18, [
                        _createVNode(_component_ErrorMessage, { name: "address" })
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _createCommentVNode("begin::Label"),
                    _cache[16] || (_cache[16] = _createElementVNode("label", { class: "required form-label fs-6 fw-bolder text-dark" }, "Phone Number", -1 /* HOISTED */)),
                    _createCommentVNode("end::Label"),
                    _createCommentVNode("begin::Input"),
                    _createVNode(_component_Field, {
                      class: "form-control border border-primary form-control-lg form-control-solid",
                      type: "text",
                      name: "phone_number",
                      modelValue: _ctx.userRecord.phone_number,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.userRecord.phone_number) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    _createCommentVNode("end::Input"),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createVNode(_component_ErrorMessage, { name: "phone_number" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _createCommentVNode("begin::Label"),
                    _cache[18] || (_cache[18] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Customer Type", -1 /* HOISTED */)),
                    _createCommentVNode("end::Label"),
                    _createCommentVNode("begin::Select"),
                    _createVNode(_component_Field, {
                      name: "role_id",
                      "data-control": "select2",
                      "data-hide-search": "true",
                      "data-placeholder": "Select Role",
                      class: "form-select border border-primary form-select-solid form-control-lg form-control-solid",
                      as: "select",
                      modelValue: _ctx.userRecord.role_id,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.userRecord.role_id) = $event))
                    }, {
                      default: _withCtx(() => [
                        _cache[17] || (_cache[17] = _createElementVNode("option", { value: "" }, "Select Role", -1 /* HOISTED */)),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (role, i) => {
                          return (_openBlock(), _createElementBlock("option", {
                            key: i,
                            value: `${role.id}`
                          }, _toDisplayString(role.name), 9 /* TEXT, PROPS */, _hoisted_24))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"]),
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, [
                        _createVNode(_component_ErrorMessage, { name: "role_id" })
                      ])
                    ]),
                    _createCommentVNode("end::Select")
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    _createCommentVNode("begin::Label"),
                    _cache[19] || (_cache[19] = _createElementVNode("label", { class: "required form-label fs-6 fw-bolder text-dark" }, "Password", -1 /* HOISTED */)),
                    _createCommentVNode("end::Label"),
                    _createCommentVNode("begin::Input"),
                    _createElementVNode("div", _hoisted_28, [
                      _createVNode(_component_Field, {
                        class: "form-control border border-primary form-control-lg form-control-solid",
                        type: _ctx.passwordVisible ? 'text' : 'password',
                        name: "password",
                        onInput: _ctx.password_check,
                        autocomplete: "off",
                        modelValue: _ctx.userRecord.password,
                        "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.userRecord.password) = $event))
                      }, null, 8 /* PROPS */, ["type", "onInput", "modelValue"]),
                      _createElementVNode("i", {
                        class: _normalizeClass(["fas fs-4 password-toggle-icon", {
      'fa-eye-slash': _ctx.passwordVisible === false,
      'fa-eye': _ctx.passwordVisible === true
    }]),
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.passwordVisible = !_ctx.passwordVisible))
                      }, null, 2 /* CLASS */)
                    ]),
                    _createElementVNode("div", {
                      class: _normalizeClass(
                    _ctx.userRecord.password.length < 1
                      ? 'container'
                      : 'd-block  gx-2 fs-9 text-danger'
                  )
                    }, [
                      _createElementVNode("span", {
                        class: _normalizeClass(["p-3", { 'frmValidation--passed': _ctx.rules.has_number }])
                      }, "Has one digit", 2 /* CLASS */),
                      _createElementVNode("span", {
                        class: _normalizeClass(["p-3", { 'frmValidation--passed': _ctx.rules.has_lowercase }])
                      }, "Has lowercase letter", 2 /* CLASS */),
                      _createElementVNode("span", {
                        class: _normalizeClass(["p-3", { 'frmValidation--passed': _ctx.rules.has_special }])
                      }, "Has Special letter", 2 /* CLASS */),
                      _createElementVNode("span", {
                        class: _normalizeClass(["p-3", { 'frmValidation--passed': _ctx.rules.has_uppercase }])
                      }, "Has Uppercase letter", 2 /* CLASS */)
                    ], 2 /* CLASS */),
                    _createCommentVNode("end::Input"),
                    _createElementVNode("div", _hoisted_29, [
                      _createElementVNode("div", _hoisted_30, [
                        _createVNode(_component_ErrorMessage, { name: "password" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_31, [
                  (_ctx.userRecord.is_admin == 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                        _createCommentVNode("begin::Label"),
                        _cache[21] || (_cache[21] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Customer ", -1 /* HOISTED */)),
                        _createCommentVNode("end::Label"),
                        _createCommentVNode("begin::Select"),
                        _createVNode(_component_Field, {
                          name: "se_account_id",
                          "data-control": "select2",
                          "data-hide-search": "true",
                          "data-placeholder": "Select Role",
                          class: "form-select border border-primary form-select-solid",
                          as: "select",
                          modelValue: _ctx.userRecord.se_account_id,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.userRecord.se_account_id) = $event))
                        }, {
                          default: _withCtx(() => [
                            _cache[20] || (_cache[20] = _createElementVNode("option", { value: "" }, "All", -1 /* HOISTED */)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (customer, i) => {
                              return (_openBlock(), _createElementBlock("option", {
                                key: i,
                                value: customer.id
                              }, _toDisplayString(customer.name), 9 /* TEXT, PROPS */, _hoisted_33))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"]),
                        _createElementVNode("div", _hoisted_34, [
                          _createElementVNode("div", _hoisted_35, [
                            _createVNode(_component_ErrorMessage, { name: "se_account_id" })
                          ])
                        ]),
                        _createCommentVNode("end::Select")
                      ]))
                    : _createCommentVNode("v-if", true)
                ])
              ]),
              _createCommentVNode("begin::Modal footer"),
              _createElementVNode("div", _hoisted_36, [
                _createCommentVNode("begin::Button"),
                _createElementVNode("button", _hoisted_37, " Clear ", 512 /* NEED_PATCH */),
                _createElementVNode("button", {
                  ref: "submitButtonRef",
                  type: "submit",
                  id: "kt_modal_create_api_key_submit",
                  class: "btn btn-primary",
                  disabled: 
                _ctx.userRecord.password.length < 8 ||
                !_ctx.rules.has_special ||
                !_ctx.rules.has_uppercase ||
                !_ctx.rules.has_lowercase
              
                }, _cache[22] || (_cache[22] = [
                  _createElementVNode("span", { class: "indicator-label" }, " Submit ", -1 /* HOISTED */),
                  _createElementVNode("span", { class: "indicator-progress" }, [
                    _createTextVNode(" Please wait... "),
                    _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                  ], -1 /* HOISTED */)
                ]), 8 /* PROPS */, _hoisted_38),
                _createCommentVNode("end::Button")
              ]),
              _createCommentVNode("end::Modal footer")
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onSubmit", "validation-schema"])
        ])
      ])
    ], 544 /* NEED_HYDRATION, NEED_PATCH */)
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}