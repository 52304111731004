
import { defineComponent, onMounted, ref,nextTick, } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Form, Field, ErrorMessage } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";
import jQuery from "jquery";
const $ = jQuery;
import {
  getCustomersList,
  addNewUsers,
  getUserRole,
} from "@/store/api/devices";
interface UserData {
  name: string;
  email: string;
  address: string;
  phone_number: string;
  password: string;
  role_id: number;
  se_account_id: number;
  is_admin: number;
}
export default defineComponent({
  name: "AddUser",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const regular =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[*_%$@])(?!.*[pPoO])\S{6,}$/;
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const cancelRef = ref(null);
    const modalRef = ref<null | HTMLElement>(null);
    const customers = ref<any[]>([]);
    const roles = ref<any[]>([]);
    const createAPIKeyModalRef = ref<null | HTMLElement>(null);
    const errors = ref<any[]>([]);
    const rules = ref({
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
    });
    const userRecord = ref<UserData>({
      name: "",
      email: "",
      address: "",
      password: "",
      phone_number: "",
      role_id: 0,
      se_account_id: 0,
      is_admin: 0,
    });
    const passwordVisible = ref(false)
    onMounted(async () => {
      getCustomersList().then((response) => {
        customers.value = response;
      });
      getUserRole().then((response) => {
        roles.value = response.data;
      });
        nextTick(() => {
        const modalElement = createAPIKeyModalRef.value;
        if (modalElement) {
          // Listen for Bootstrap modal events
          modalElement.addEventListener('shown.bs.modal', onModalShown);
        }
      });
    });
    const password_check = () => {
      rules.value.has_number = /(?=.*[0-9])/.test(userRecord.value.password);
      rules.value.has_lowercase = /(?=.*[a-z])/.test(userRecord.value.password);
      rules.value.has_uppercase = /[A-Z]/.test(userRecord.value.password);
      rules.value.has_special = /(?=.*[^A-Za-z0-9])/.test(
        userRecord.value.password
      );
    };
    const adduserSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .label("Name"),

  address: Yup.string()
    .required("Address is required")
    .label("Address"),

  email: Yup.string()
    .required("Email is required")
    .email("Email is invalid")
    .label("Email"),

  se_account_id: Yup.string()
    .required("Customer is required")
    .label("Customer"),

  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required")
    .label("Password"),

  role_id: Yup.number()
    .required("Customer Type is required")
    .label("Customer Type"),

  is_admin: Yup.number()
    .required("User Type is required")
    .label("User Type"),
});

    const addAdminSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .label("Name"),

  address: Yup.string()
    .required("Address is required")
    .label("Address"),

  email: Yup.string()
    .required("Email is required")
    .email("Email is invalid")
    .label("Email"),

  password: Yup.string()
    .min(8, "Password must be at least 8 characters long")
    .required("Password is required")
    .label("Password"),

  role_id: Yup.number()
    .required("Customer Type is required")
    .label("Customer Type"),

  is_admin: Yup.number()
    .required("User Type is required")
    .label("User Type"),
});

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      console.log(userRecord.value);
      if (userRecord.value.is_admin == 1) {
        // delete userRecord.value.se_account_id;
      }

      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      addNewUsers(userRecord.value)
        .then((res) => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          document.getElementById("addusermodal")?.click();
          Swal.fire({
            icon: "success",
            title: "User added Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch((e) => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          errors.value = e.response.data;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${errors.value ? errors.value : e}`,
          });
        });
    };

    const onUserTypeChange = (e) => {
      console.log(e);
    };
    // This function will be called when the modal is opened
    const onModalShown = () => {
      if (cancelRef.value) {
        cancelRef.value.click();
      }
    };
    return {
      userRecord,
      adduserSchema,
      customers,
      createAPIKeyModalRef,
      roles,
      rules,
      submit,
      password_check,
      submitButtonRef,
      modalRef,
      onUserTypeChange,
      addAdminSchema,
      cancelRef,
      onModalShown,
      passwordVisible
    };
  },
});
