import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "editusermodal",
  tabindex: "-1",
  ref: "createAPIKeyModalRef",
  "aria-labelledby": "exampleModalLabel",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered modal-lg" }
const _hoisted_3 = { class: "modal-content rounded" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "row justify-content-around" }
const _hoisted_6 = { class: "col-md-5" }
const _hoisted_7 = { class: "col-md-5" }
const _hoisted_8 = { class: "row justify-content-around" }
const _hoisted_9 = { class: "col-md-5" }
const _hoisted_10 = { class: "col-md-5" }
const _hoisted_11 = { class: "row justify-content-around" }
const _hoisted_12 = { class: "col-md-5" }
const _hoisted_13 = ["value"]
const _hoisted_14 = { class: "col-md-5" }
const _hoisted_15 = ["value"]
const _hoisted_16 = { class: "modal-footer d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" Modal "),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "modal-header bg-primary" }, [
            _createElementVNode("span", {
              class: "modal-title h5 text-uppercase text-white",
              id: "exampleModalLabel",
              style: {"margin-left":"1.1rem"}
            }, " Update User Details "),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-icon btn-sm",
              "data-bs-dismiss": "modal",
              "aria-label": "Close",
              id: "editnewmodalclose"
            }, [
              _createElementVNode("i", {
                class: "bi bi-x-circle-fill light-text fs-3",
                style: {"color":"white"}
              })
            ])
          ], -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[7] || (_cache[7] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Name ", -1 /* HOISTED */)),
                _withDirectives(_createElementVNode("input", {
                  class: "form-control",
                  placeholder: "Enter Your Name",
                  type: "text",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.user.name) = $event)),
                  required: ""
                }, null, 512 /* NEED_PATCH */), [
                  [_vModelText, _ctx.user.name]
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[8] || (_cache[8] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Email ", -1 /* HOISTED */)),
                _withDirectives(_createElementVNode("input", {
                  class: "form-control",
                  placeholder: "Enter Email",
                  type: "email",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.user.email) = $event))
                }, null, 512 /* NEED_PATCH */), [
                  [_vModelText, _ctx.user.email]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _cache[9] || (_cache[9] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Address ", -1 /* HOISTED */)),
                _withDirectives(_createElementVNode("input", {
                  class: "form-control",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.user.address) = $event)),
                  placeholder: "Address",
                  type: "text"
                }, null, 512 /* NEED_PATCH */), [
                  [_vModelText, _ctx.user.address]
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _cache[10] || (_cache[10] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Phone Number ", -1 /* HOISTED */)),
                _withDirectives(_createElementVNode("input", {
                  class: "form-control",
                  placeholder: "Phone Number",
                  type: "text",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.user.phone_number) = $event))
                }, null, 512 /* NEED_PATCH */), [
                  [_vModelText, _ctx.user.phone_number]
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _cache[11] || (_cache[11] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "User Role ", -1 /* HOISTED */)),
                _withDirectives(_createElementVNode("select", {
                  class: "form-select border border-primary form-select-solid",
                  "data-control": "select2",
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.user.role_id) = $event)),
                  "data-dropdown-parent": "#kt_modal_1",
                  "data-placeholder": "Select Role",
                  "data-allow-clear": "true"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.roles, (role, i) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: i,
                      value: `${role.id}`
                    }, _toDisplayString(role.name), 9 /* TEXT, PROPS */, _hoisted_13))
                  }), 128 /* KEYED_FRAGMENT */))
                ], 512 /* NEED_PATCH */), [
                  [_vModelSelect, _ctx.user.role_id]
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _cache[12] || (_cache[12] = _createElementVNode("label", { class: "required fs-5 fw-bold mb-2" }, "Company Name ", -1 /* HOISTED */)),
                _withDirectives(_createElementVNode("select", {
                  class: "form-select border border-primary form-select-solid",
                  "data-control": "select3",
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.user.se_account_id) = $event)),
                  "data-dropdown-parent": "#kt_modal_1",
                  "data-placeholder": "Select Sompany",
                  "data-allow-clear": "true",
                  disabled: ""
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (customer, i) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: i,
                      value: customer.id
                    }, _toDisplayString(customer.name), 9 /* TEXT, PROPS */, _hoisted_15))
                  }), 128 /* KEYED_FRAGMENT */))
                ], 512 /* NEED_PATCH */), [
                  [_vModelSelect, _ctx.user.se_account_id]
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("button", {
              ref: "submitButtonRef",
              type: "submit",
              id: "kt_modal_create_api_key_submit",
              class: "btn btn-primary",
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args))),
              "data-dismiss": "modal"
            }, _cache[13] || (_cache[13] = [
              _createElementVNode("span", { class: "indicator-label" }, " Submit ", -1 /* HOISTED */),
              _createElementVNode("span", { class: "indicator-progress" }, [
                _createTextVNode(" Please wait... "),
                _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
              ], -1 /* HOISTED */)
            ]), 512 /* NEED_PATCH */)
          ])
        ])
      ])
    ], 512 /* NEED_PATCH */)
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}