
import { defineComponent, ref, watch } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ChangeUserPassword } from "@/store/api/devices";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { hideModal } from "@/core/helpers/dom";
interface Chan_pass {
  password: string;
  confirm_password: string;
}
export default defineComponent({
  name: "ChangePassword",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  props: {
    id: { type: Number, required: true },
  },
  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const modalRef = ref<null | HTMLElement>(null);
    const id = ref();
    const rules = ref({
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
    });
    const createAPIKeyModalRef = ref<null | HTMLElement>(null);
    const payload = ref<Chan_pass>({
      password: "",
      confirm_password: "",
    });
    const passwordVisible = ref(false);
    const confirmPasswordVisible = ref(false);
    watch(
      () => props.id,
      (v) => {
        id.value = v;
      }
    );
    const valid = Yup.object().shape({
      password: Yup.string().min(8).required().label("Password"),
      confirm_password: Yup.string()
        .min(8)
        .required()
        .label("Confirm Password"),
    });
    const password_check = () => {
      rules.value.has_number = /(?=.*[0-9])/.test(payload.value.password);
      rules.value.has_lowercase = /(?=.*[a-z])/.test(payload.value.password);
      rules.value.has_uppercase = /[A-Z]/.test(payload.value.password);
      rules.value.has_special = /(?=.*[^A-Za-z0-9])/.test(
        payload.value.password
      );
    };
    const submit = async () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      if (payload.value.password === payload.value.confirm_password) {
        let json = {
          id: id.value,
          password: payload.value.password,
        };
        await ChangeUserPassword(json).then((res) => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          Swal.fire({
            text: `${res.data.message}`,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            document.getElementById("changepasswordclosemodal")?.click();
          });
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Password not match! Try again",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      }
    };

    return {
      payload,
      valid,
      rules,
      password_check,
      submit,
      createAPIKeyModalRef,
      submitButtonRef,
      modalRef,
      passwordVisible,
      confirmPasswordVisible
    };
  },
});
