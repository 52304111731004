import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "changepasswordmodal",
  ref: "createAPIKeyModalRef",
  tabindex: "-1",
  "aria-labelledby": "exampleModalLabel",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered modal-lg" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "row justify-content-around" }
const _hoisted_6 = { class: "col-md-5" }
const _hoisted_7 = { class: "password-field-wrapper" }
const _hoisted_8 = { class: "fv-plugins-message-container" }
const _hoisted_9 = { class: "fv-help-block" }
const _hoisted_10 = { class: "col-md-5" }
const _hoisted_11 = { class: "password-field-wrapper" }
const _hoisted_12 = { class: "fv-plugins-message-container" }
const _hoisted_13 = { class: "fv-help-block" }
const _hoisted_14 = { class: "modal-footer d-flex justify-content-center" }
const _hoisted_15 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" Modal "),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[7] || (_cache[7] = _createElementVNode("div", { class: "modal-header bg-primary" }, [
            _createElementVNode("span", {
              class: "modal-title h5 text-uppercase text-white",
              id: "exampleModalLabel",
              style: {"margin-left":"1.1rem"}
            }, " Change User Password "),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-icon btn-sm",
              "data-bs-dismiss": "modal",
              "aria-label": "Close",
              id: "changepasswordclosemodal"
            }, [
              _createElementVNode("i", {
                class: "bi bi-x-circle-fill light-text fs-3",
                style: {"color":"white"}
              })
            ])
          ], -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Form, {
              class: "form w-100",
              id: "kt_login_signin_form",
              onSubmit: _ctx.submit,
              "validation-schema": _ctx.valid
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createCommentVNode("begin::Label"),
                    _cache[5] || (_cache[5] = _createElementVNode("label", { class: "form-label fs-6 fw-bolder text-dark" }, "Password", -1 /* HOISTED */)),
                    _createCommentVNode("end::Label"),
                    _createCommentVNode("begin::Input"),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_Field, {
                        class: "form-control border border-primary form-control-lg form-control-solid",
                        type: _ctx.passwordVisible ? 'text' : 'password',
                        onInput: _ctx.password_check,
                        name: "password",
                        autocomplete: "off",
                        modelValue: _ctx.payload.password,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payload.password) = $event))
                      }, null, 8 /* PROPS */, ["type", "onInput", "modelValue"]),
                      _createElementVNode("i", {
                        class: _normalizeClass(["fas fs-4 password-toggle-icon", {
                      'fa-eye-slash': _ctx.passwordVisible === false,
                      'fa-eye': _ctx.passwordVisible === true
                    }]),
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.passwordVisible = !_ctx.passwordVisible))
                      }, null, 2 /* CLASS */)
                    ]),
                    _createElementVNode("div", {
                      class: _normalizeClass(
                    _ctx.payload.password.length < 1
                      ? 'container'
                      : 'd-block  gx-2 fs-9 text-danger'
                  )
                    }, [
                      _createElementVNode("span", {
                        class: _normalizeClass(["p-3", { 'frmValidation--passed': _ctx.rules.has_number }])
                      }, "Has one digit", 2 /* CLASS */),
                      _createElementVNode("span", {
                        class: _normalizeClass(["p-3", { 'frmValidation--passed': _ctx.rules.has_lowercase }])
                      }, "Has lowercase letter", 2 /* CLASS */),
                      _createElementVNode("span", {
                        class: _normalizeClass(["p-3", { 'frmValidation--passed': _ctx.rules.has_special }])
                      }, "Has Special letter", 2 /* CLASS */),
                      _createElementVNode("span", {
                        class: _normalizeClass(["p-3", { 'frmValidation--passed': _ctx.rules.has_uppercase }])
                      }, "Has Uppercase letter", 2 /* CLASS */)
                    ], 2 /* CLASS */),
                    _createCommentVNode("end::Input"),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_ErrorMessage, { name: "password" })
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createCommentVNode("begin::Label"),
                    _cache[6] || (_cache[6] = _createElementVNode("label", { class: "form-label fs-6 fw-bolder text-dark" }, "Confirm Password", -1 /* HOISTED */)),
                    _createCommentVNode("end::Label"),
                    _createCommentVNode("begin::Input"),
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_Field, {
                        class: "form-control border border-primary form-control-lg form-control-solid",
                        type:  _ctx.confirmPasswordVisible ? 'text' : 'password',
                        name: "confirm_password",
                        autocomplete: "off",
                        modelValue: _ctx.payload.confirm_password,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.payload.confirm_password) = $event))
                      }, null, 8 /* PROPS */, ["type", "modelValue"]),
                      _createElementVNode("i", {
                        class: _normalizeClass(["fas fs-4 password-toggle-icon", {
                      'fa-eye-slash': _ctx.confirmPasswordVisible === false,
                      'fa-eye': _ctx.confirmPasswordVisible === true
                    }]),
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.confirmPasswordVisible = !_ctx.confirmPasswordVisible))
                      }, null, 2 /* CLASS */)
                    ]),
                    _createCommentVNode("end::Input"),
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_ErrorMessage, { name: "confirm_password" })
                      ])
                    ])
                  ])
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onSubmit", "validation-schema"])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("button", {
              ref: "submitButtonRef",
              class: "btn btn-primary",
              disabled: 
              _ctx.payload.password.length < 8 ||
              !_ctx.rules.has_special ||
              !_ctx.rules.has_uppercase ||
              !_ctx.rules.has_lowercase
            ,
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
            }, " Submit ", 8 /* PROPS */, _hoisted_15)
          ])
        ])
      ])
    ], 512 /* NEED_PATCH */)
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}