import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "card",
  style: {"width":"100%","border":"0px"}
}
const _hoisted_2 = {
  class: "card-header border-0",
  style: {"margin":"-12px 0px"}
}
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-2" }
const _hoisted_6 = ["value"]
const _hoisted_7 = {
  key: 0,
  class: "card-toolbar"
}
const _hoisted_8 = {
  class: "card-body pt-0",
  style: {"width":"100%"}
}
const _hoisted_9 = { class: "d-flex" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTDatatable = _resolveComponent("KTDatatable")!
  const _component_AddUser = _resolveComponent("AddUser")!
  const _component_EditUserProfile = _resolveComponent("EditUserProfile")!
  const _component_ChangePassword = _resolveComponent("ChangePassword")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode("begin::Card"),
    _createElementVNode("div", _hoisted_1, [
      _cache[8] || (_cache[8] = _createElementVNode("h4", { class: "text-primary mx-10" }, "Users", -1 /* HOISTED */)),
      _createCommentVNode("begin::Card header"),
      _createElementVNode("div", _hoisted_2, [
        _createCommentVNode("begin::Card title"),
        _createElementVNode("div", _hoisted_3, [
          _createCommentVNode("begin::Search"),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen021.svg" })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
              "data-kt-subscription-table-filter": "search",
              class: "form-control border mr-3 border-primary form-control-solid w-250px ps-14",
              placeholder: "Search User"
            }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
              [_vModelText, _ctx.search]
            ]),
            _createElementVNode("select", {
              class: "form-select border ms-5 border-primary form-select-solid",
              "data-control": "select2",
              onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onCustomerChange && _ctx.onCustomerChange(...args))),
              "data-dropdown-parent": "#kt_modal_1",
              "data-placeholder": "Select an option",
              "data-allow-clear": "true"
            }, [
              _cache[3] || (_cache[3] = _createElementVNode("option", { value: "" }, "All", -1 /* HOISTED */)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.customers, (customer, i) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: i,
                  value: customer.id
                }, _toDisplayString(customer.name + " (ID: " + customer.id + ")"), 9 /* TEXT, PROPS */, _hoisted_6))
              }), 128 /* KEYED_FRAGMENT */))
            ], 32 /* NEED_HYDRATION */)
          ]),
          _createCommentVNode("end::Search")
        ]),
        _createCommentVNode("begin::Card title"),
        _createCommentVNode("begin::Card toolbar"),
        (_ctx.permissions.includes('add-user'))
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[4] || (_cache[4] = [
              _createElementVNode("button", {
                class: "btn btn-primary",
                "data-bs-toggle": "modal",
                "data-bs-target": "#addusermodal"
              }, " Add User ", -1 /* HOISTED */)
            ])))
          : _createCommentVNode("v-if", true),
        _createCommentVNode("end::Card toolbar")
      ]),
      _createCommentVNode("end::Card header"),
      _createCommentVNode("begin::Card body"),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_KTDatatable, {
          "table-data": _ctx.userdata,
          total: _ctx.total,
          "table-header": _ctx.headerConfig,
          "enable-items-per-page-dropdown": true,
          loading: _ctx.loading,
          rowsPerPage: _ctx.pagination.page_size,
          onCurrentChange: _ctx.currentChange,
          onItemsPerPageChange: _ctx.itemsPerPageChange
        }, {
          "cell-id": _withCtx(({ row: users }) => [
            _createElementVNode("div", null, _toDisplayString(users.id), 1 /* TEXT */)
          ]),
          "cell-name": _withCtx(({ row: users }) => [
            _createElementVNode("div", null, _toDisplayString(users.name), 1 /* TEXT */)
          ]),
          "cell-email": _withCtx(({ row: users }) => [
            _createTextVNode(_toDisplayString(users.email), 1 /* TEXT */)
          ]),
          "cell-phone_number": _withCtx(({ row: users }) => [
            _createTextVNode(_toDisplayString(users.phone_number), 1 /* TEXT */)
          ]),
          "cell-address": _withCtx(({ row: users }) => [
            _createTextVNode(_toDisplayString(users.address), 1 /* TEXT */)
          ]),
          "cell-se_account_id": _withCtx(({ row: users }) => [
            _createTextVNode(_toDisplayString(users.se_account_id
              ? _ctx.customers.find((v) => v.id == users.se_account_id).name
              : "N/A"), 1 /* TEXT */)
          ]),
          "cell-diagnostic_uplink": _withCtx(({ row: users }) => [
            _createElementVNode("div", _hoisted_9, [
              (_ctx.permissions.includes('update-user'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 0,
                    href: "#",
                    class: "text-gray-600 text-hover-primary mx-1",
                    title: "Edit",
                    onClick: ($event: any) => (_ctx.getUserDetail(users))
                  }, _cache[5] || (_cache[5] = [
                    _createElementVNode("div", {
                      class: _normalizeClass(`badge badge-light-success`)
                    }, [
                      _createElementVNode("i", {
                        class: "fas fa-edit text-success",
                        "data-bs-toggle": "modal",
                        "data-bs-target": "#editusermodal"
                      })
                    ], -1 /* HOISTED */)
                  ]), 8 /* PROPS */, _hoisted_10))
                : _createCommentVNode("v-if", true),
              (_ctx.permissions.includes('change-users-password'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 1,
                    href: "#",
                    class: "text-gray-600 text-hover-primary mx-1",
                    title: "Edit",
                    onClick: ($event: any) => (_ctx.getUserId(users.id))
                  }, _cache[6] || (_cache[6] = [
                    _createElementVNode("div", {
                      class: _normalizeClass(`badge badge-light-success`)
                    }, [
                      _createElementVNode("i", {
                        class: "fas fa-lock text-success",
                        "data-bs-toggle": "modal",
                        "data-bs-target": "#changepasswordmodal"
                      })
                    ], -1 /* HOISTED */)
                  ]), 8 /* PROPS */, _hoisted_11))
                : _createCommentVNode("v-if", true),
              (_ctx.permissions.includes('delete-user'))
                ? (_openBlock(), _createElementBlock("a", {
                    key: 2,
                    href: "#",
                    class: "text-gray-600 text-hover-primary mb-1",
                    title: "Delete",
                    onClick: ($event: any) => (_ctx.delteUser(users.id))
                  }, _cache[7] || (_cache[7] = [
                    _createElementVNode("div", {
                      class: _normalizeClass(`badge badge-light-success`)
                    }, [
                      _createElementVNode("i", { class: "fas fa-trash text-danger" })
                    ], -1 /* HOISTED */)
                  ]), 8 /* PROPS */, _hoisted_12))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["table-data", "total", "table-header", "loading", "rowsPerPage", "onCurrentChange", "onItemsPerPageChange"])
      ]),
      _createCommentVNode("end::Card body")
    ]),
    _createCommentVNode("end::Card"),
    _createVNode(_component_AddUser),
    _createVNode(_component_EditUserProfile, { userRec: _ctx.userDataforUpdate }, null, 8 /* PROPS */, ["userRec"]),
    _createVNode(_component_ChangePassword, { id: _ctx.UserId }, null, 8 /* PROPS */, ["id"])
  ], 64 /* STABLE_FRAGMENT */))
}